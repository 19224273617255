const portfolioItems = [
  {
    "id": "webdev-project",
    "title": "Landing Page Responsiva",
    "description": "Desenvolvimento de página web comercial, com estrutura responsiva e otimizada para melhor rankeamento em mecanismos de busca.",
    "tags": [
      "Front-End",
      "SEO",
      "Branding"
    ],
    "featuredImage": "src/assets/images/portfolio/webdev-project.png"
  }
];

export default portfolioItems;